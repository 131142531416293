import React, {useEffect, useState} from 'react';
import {StaticImage, getImage} from 'gatsby-plugin-image';
import Layout from '../../components/Layout';
import SocialMediaIcons from '../../components/SocialMediaIcons';
import SectionIntro from '../../components/SectionIntro';
import Seo from '../../components/SEO';
import {TitleBordered} from '../../components/utils';

// Background images
import {graphql, useStaticQuery} from 'gatsby';
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import QuestionSection from '../../components/QuestionSection';

const dataProfile = {
  emiliano: {
    name: 'Emiliano Berenbaum',
    role: 'Founder & CEO',
    description:
      'Emiliano has been working on enterprise products for his whole career, from database security solutions to network monitoring. He was the first employee at Okta and a key architect in pioneering cloud single sign-on and user management solutions. Emiliano was a co-founder and CTO of Scytale (acquired by HPE). He helped to develop the standards for SPIFFE—the CNCF-adopted standard for workload identity.',
    linkedInLink: 'http://linkedin.com/in/eberenb',
    twitterLink: 'https://twitter.com/y2bishop2y',
  },
  josh: {
    name: 'Josh Lange',
    role: 'Chief Revenue Officer',
    description:
      'With over 15 years of experience driving growth in customer teams, Josh is a seasoned expert in go-to-market strategies and channel partnerships. Josh’s ability to translate customer problems into fitting solutions has been a key factor in his success at early-stage startups and established firms. His experience comes from significant roles at companies such as Call Fire, EZ Texting, Sonar, and Marchex. His key to success is that he always puts people first.',
    linkedInLink: 'https://www.linkedin.com/in/joshdlange',
    twitterLink: 'https://twitter.com/TrustleSecurity',
  },
  rick: {
    name: 'Rick Grinnell',
    role: 'Founder & Managing Partner of Glasswing Ventures',
    description:
      'Rick Grinnell is Founder and Managing Partner of Glasswing Ventures, focusing on investments in AI-enabled security and enterprise infrastructure. Rick has led investments and serves on the Board of Directors of Allure Security, Lambent Spaces (formerly Armored Things), Black Kite, and Terbium Labs (acquired by Deloitte). ',
    linkedInLink: 'https://www.linkedin.com/in/rickgrinnell',
    twitterLink: 'https://twitter.com/rickgrinnell',
  },
  marc: {
    name: 'Marc Boroditsky',
    role: 'President of Revenue Cloudflare, Former CRO Twilio',
    description:
      'Seasoned tech executive with 30+ years of software experience including 25+ years with start-ups. Founded, financed, and scaled four start-ups and successfully completed exits for three including sales to Cisco, Oracle, and Twilio and supported the subsequent successful IPO of TWLO. Through the combination of developing a customer and market-driven vision, hands-on leadership, and incremental metric-driven results, built solid teams that have delivered innovative products, developed new markets, and generated sales and company results above expectations.',
    linkedInLink: 'https://www.linkedin.com/in/marcboroditsky',
    twitterLink: 'https://twitter.com/marcboroditsky',
  },
};
const {emiliano, rick, marc} = dataProfile;

const SimpleLink = ({url = '/', label = ''}) => {
  return (
    <a href={url} target="_blank" rel="noreferrer" className="hover:underline">
      <b>{label}</b>
    </a>
  );
};

const BoardManagement = ({setModalShowing}) => {
  return (
    <>
      <div className="py-8 text-2xl font-bold text-center text-black">Our Board</div>
      <div
        className="relative items-center hidden cursor-pointer group xl:flex"
        onClick={() => setModalShowing(true)}
        onKeyDown={() => setModalShowing(true)}
        role="presentation"
      >
        <div className="mr-4 transition duration-300 ease-in-out group-hover:brightness-100">
          <StaticImage
            className="mb-10 lg:mb-0 lg:max-w-[252px] 2xl:max-w-[304px] 3xl:max-w-[380px]"
            src="../../images/team/profiles/Emiliano.jpg"
            placeholder="blurred"
            alt="Emiliano Berenbaum"
          />
        </div>
        <div className="mr-4 transition duration-300 ease-in-out group-hover:brightness-100">
          <StaticImage
            className="mb-10 lg:mb-0 lg:max-w-[252px] 2xl:max-w-[304px] 3xl:max-w-[380px]"
            src="../../images/team/profiles/Rick.jpg"
            placeholder="blurred"
            alt="Rick Grinnell"
          />
        </div>
        <div className="transition duration-300 ease-in-out group-hover:brightness-100">
          <StaticImage
            className="mb-10 lg:mb-0 lg:max-w-[252px] 2xl:max-w-[304px] 3xl:max-w-[380px]"
            src="../../images/team/profiles/Marc.jpg"
            placeholder="blurred"
            alt="Marc Boroditsky"
          />
        </div>
      </div>
    </>
  );
};

const CardInfo = ({name, role, description, linkedInLink, twitterLink, img, showData, setShowData, stateName}) => {
  return (
    <>
      {/* Desktop */}

      <div className="flex-col items-center w-full lg:w-[700px] lg:mx-auto xl:mx-0 xl:w-[430px] 2xl:w-[580px] mb-8 bg-white text-blue filter drop-shadow-lg group relative overflow-hidden hidden lg:flex">
        <div className="absolute w-full h-full transition duration-200 ease-in-out opacity-1 group-hover:opacity-20">
          {img}
        </div>
        <div className="relative flex flex-col justify-between transition duration-200 ease-in-out opacity-0 group-hover:opacity-100 lg:h-[420px] 2xl:h-[580px] p-7 md:px-10 md:py-12 xl:p-6 2xl:p-7">
          <div className="pt-0 lg:pt-4">
            <div className="w-auto font-bold md:w-80 -top-20 sm:-top-28 left-48 sm:left-52 lg:static lg:w-full">
              <h3 className="pb-2 text-xl md:text-3xl">{name}</h3>
              <h4 className="pb-2 text-sm">{role}</h4>
            </div>
            <p
              className="mt-4 mb-8 text-sm lg:text-lg xl:text-sm 2xl:text-lg md:my-0"
              dangerouslySetInnerHTML={{__html: description}}
            ></p>
          </div>
          <SocialMediaIcons {...{linkedInLink, twitterLink}} />
        </div>
      </div>

      {/* Mobile */}

      <div
        className="flex flex-col items-center w-full xl:w-[580px] mb-8 bg-white text-blue filter drop-shadow-lg overflow-hidden lg:hidden "
        onClick={() => setShowData({...showData, [stateName]: !showData[stateName]})}
        role="presentation"
      >
        <div
          className={`absolute w-full cursor-pointer ${
            showData && showData[stateName] ? 'opacity-0 z-10' : 'opacity-100 z-30'
          }`}
        >
          {img}
        </div>

        <div
          className={`relative flex flex-col justify-between xl:h-[580px] p-7 md:px-10 md:py-12 cursor-pointer z-30 ${
            showData && !showData[stateName] ? 'opacity-0 z-10' : 'opacity-100 z-20'
          }`}
        >
          <div className="flex justify-end transition duration-200 ease-in-out lg:hidden">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.962599 0.634886C1.42575 0.172486 2.17667 0.172486 2.63982 0.634886L6.54511 4.53384L10.4504 0.634886C10.9136 0.172486 11.6645 0.172486 12.1276 0.634886C12.5908 1.09729 12.5908 1.84699 12.1276 2.30939L8.22234 6.20834L12.1276 10.1073C12.5908 10.5697 12.5908 11.3194 12.1276 11.7818C11.6645 12.2442 10.9136 12.2442 10.4504 11.7818L6.54511 7.88284L2.63982 11.7818C2.17667 12.2442 1.42575 12.2442 0.962599 11.7818C0.499446 11.3194 0.499446 10.5697 0.962599 10.1073L4.86789 6.20834L0.962599 2.30939C0.499446 1.84699 0.499446 1.09729 0.962599 0.634886Z"
                fill="#363636"
              />
            </svg>
          </div>
          <div className="pt-0 lg:pt-4">
            <div className="w-auto font-bold md:w-80 -top-20 sm:-top-28 left-48 sm:left-52 lg:static lg:w-full">
              <h3 className="pb-2 text-xl md:text-3xl">{name}</h3>
              <h4 className="text-sm">{role}</h4>
            </div>
            <p
              className="mt-4 mb-8 text-sm lg:text-lg md:mt-10 lg:mt-6"
              dangerouslySetInnerHTML={{__html: description}}
            ></p>
          </div>
          <SocialMediaIcons {...{linkedInLink, twitterLink}} />
        </div>
      </div>
    </>
  );
};

const BoardModal = ({showing, close}) => {
  return (
    <div
      className="fixed inset-0 z-40 flex flex-col items-center justify-center lg:flex-row"
      style={{visibility: showing ? 'visible' : 'hidden'}}
    >
      <div
        className="absolute inset-0 flex flex-col bg-white lg:flex opacity-80"
        onClick={close}
        onKeyDown={close}
        aria-label="close window"
        role="button"
        tabIndex={0}
      />
      <CardInfo
        name={emiliano.name}
        role={emiliano.role}
        description={emiliano.description}
        linkedInLink={emiliano.linkedInLink}
        twitterLink={emiliano.twitterLink}
        img={
          <StaticImage
            src="../../images/profiles/EmilianoBerenbaum.jpg"
            placeholder="blurred"
            className="h-full"
            alt="Emiliano Berenbaum"
          />
        }
      />
      <CardInfo
        name={rick.name}
        role={rick.role}
        description={rick.description}
        linkedInLink={rick.linkedInLink}
        twitterLink={rick.twitterLink}
        img={
          <StaticImage
            src="../../images/profiles/Rick.jpg"
            placeholder="blurred"
            className="h-full"
            alt="Rick Grinnell"
          />
        }
      />
      <CardInfo
        name={marc.name}
        role={marc.role}
        description={marc.description}
        linkedInLink={marc.linkedInLink}
        twitterLink={marc.twitterLink}
        img={
          <StaticImage
            src="../../images/profiles/Marc.jpg"
            placeholder="blurred"
            className="h-full"
            alt="Marc Boroditsky"
          />
        }
      />
    </div>
  );
};

const BoardModalMobile = () => {
  const [showData, setShowData] = useState({emiliano: false, josh: false});
  return (
    <div className="flex-col xl:hidden">
      <CardInfo
        name={emiliano.name}
        role={emiliano.role}
        description={emiliano.description}
        linkedInLink={emiliano.linkedInLink}
        twitterLink={emiliano.twitterLink}
        img={
          <StaticImage
            src="../../images/team/profiles/Emiliano.jpg"
            placeholder="blurred"
            alt="Emiliano Berenbaum"
            layout="constrained"
            className="w-full h-[500px]"
          />
        }
        stateName="emiliano"
        showData={showData}
        setShowData={setShowData}
      />
      <CardInfo
        name={rick.name}
        role={rick.role}
        description={rick.description}
        linkedInLink={rick.linkedInLink}
        twitterLink={rick.twitterLink}
        img={
          <StaticImage
            src="../../images/team/profiles/Rick.jpg"
            placeholder="blurred"
            alt="Rick Grinnell"
            className="w-full h-[500px]"
          />
        }
        stateName="rick"
        showData={showData}
        setShowData={setShowData}
      />
      <CardInfo
        name={marc.name}
        role={marc.role}
        description={marc.description}
        linkedInLink={marc.linkedInLink}
        twitterLink={marc.twitterLink}
        img={
          <StaticImage
            src="../../images/team/profiles/Marc.jpg"
            placeholder="blurred"
            alt="Marc Boroditsky"
            className="w-full h-[650px]"
          />
        }
        stateName="marc"
        showData={showData}
        setShowData={setShowData}
      />
    </div>
  );
};

const Profiles = () => {
  const imageCss = 'w-[271px] group-hover:opacity-0 transition duration-300 ease-in-out  ';
  const avatarCss =
    'w-[271px] group-hover:opacity-100 1mix-blend-multiply bg-[#AFAFAF80] 1brightness-75 opacity-0 z-20 left-0 transition duration-300 ease-in-out';

  const memberCss = 'text-xl xl:text-2xl font-bold text-black pl-2 my-2';

  return (
    <div className="flex flex-col">
      <div className="py-8 text-2xl font-bold text-center text-black ">Our Team</div>
      <div className="relative grid items-center justify-center grid-cols-1 gap-4 px-8 mx-auto cursor-pointer md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 xl:justify-start md:flex-row md:px-0">
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Andres.png"
              placeholder="blurred"
              alt="Andres"
            />
            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Andres.jpg"
              placeholder="blurred"
              alt="Andres Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Andres</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Christian.jpg"
              placeholder="blurred"
              alt="Christian"
            />
            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Christian.jpg"
              placeholder="blurred"
              alt="Christian Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Christian</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={`${imageCss}`}
              src="../../images/team/profiles/Emiliano.jpg"
              placeholder="blurred"
              alt="Emiliano"
              quality={100}
            />
            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Emiliano.jpg"
              placeholder="blurred"
              alt="Emiliano Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Emiliano</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Facundo.jpg"
              placeholder="blurred"
              alt="Facundo"
            />
            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Facundo.jpg"
              placeholder="blurred"
              alt="Facundo"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Facundo</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Geremy.jpg"
              placeholder="blurred"
              alt="Geremy"
            />
            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Geremy.jpg"
              placeholder="blurred"
              alt="Geremy Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Geremy</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Harry.jpg"
              placeholder="blurred"
              alt="Harry Avatar"
            />

            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Harry.jpg"
              placeholder="blurred"
              alt="Harry Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Harry</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Ian.jpg"
              placeholder="blurred"
              alt="Ian"
              quality={100}
            />

            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Ian.jpg"
              placeholder="blurred"
              alt="Ian Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Ian</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Indiana.jpg"
              placeholder="blurred"
              alt="Indiana"
            />

            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Indiana.jpg"
              placeholder="blurred"
              alt="Indiana Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Indiana</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Kallen.jpg"
              placeholder="blurred"
              alt="Kallen"
            />
            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Kallen.jpg"
              placeholder="blurred"
              alt="Kallen"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Kallen</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Kyle.jpg"
              placeholder="blurred"
              quality={100}
              alt="Kyle"
            />
            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Kyle.jpg"
              placeholder="blurred"
              alt="Kyle Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Kyle</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Luciano.jpg"
              placeholder="blurred"
              alt="Luciano"
            />
            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Luciano.jpg"
              placeholder="blurred"
              alt="Luciano"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Luciano</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Martin.jpg"
              placeholder="blurred"
              alt="Martin"
            />
            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Martin.jpg"
              placeholder="blurred"
              alt="Martin"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Martin</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Nicolas.jpg"
              placeholder="blurred"
              alt="Nicolas"
            />
            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Nicolas.jpg"
              placeholder="blurred"
              alt="Nicolas Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Nicolas</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Ricardo.jpg"
              placeholder="blurred"
              alt="Ricardo"
            />

            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Ricardo.jpg"
              placeholder="blurred"
              alt="Ricardo Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Ricardo</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className={imageCss}
              src="../../images/team/profiles/Seth.jpg"
              placeholder="blurred"
              alt="Seth"
            />
            <StaticImage
              className={avatarCss}
              src="../../images/team/profiles/avatars/Seth.jpg"
              placeholder="blurred"
              alt="Seth Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Seth</div>
          </div>
        </div>{' '}
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className="w-[271px]"
              src="../../images/team/profiles/Scott.jpg"
              placeholder="blurred"
              alt="Scott"
            />
            <StaticImage
              className="w-[271px]"
              src="../../images/team/profiles/Scott.jpg"
              placeholder="blurred"
              alt="Scott Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Scott</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className="w-[271px]"
              src="../../images/team/profiles/Person.jpg"
              placeholder="blurred"
              alt="Chris"
            />
            <StaticImage
              className="w-[271px]"
              src="../../images/team/profiles/Person.jpg"
              placeholder="blurred"
              alt="Chris Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Chris</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className="w-[271px]"
              src="../../images/team/profiles/Person.jpg"
              placeholder="blurred"
              alt="Betty"
            />
            <StaticImage
              className="w-[271px]"
              src="../../images/team/profiles/Person.jpg"
              placeholder="blurred"
              alt="Betty Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Betty</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className="w-[271px]"
              src="../../images/team/profiles/Person.jpg"
              placeholder="blurred"
              alt="Eric"
            />
            <StaticImage
              className="w-[271px]"
              src="../../images/team/profiles/Person.jpg"
              placeholder="blurred"
              alt="Eric Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Eric</div>
          </div>
        </div>
        <div className="relative mb-12">
          <div className="top-0 left-0 transition duration-300 ease-in-out group ">
            <StaticImage
              className="w-[271px]"
              src="../../images/team/profiles/Person.jpg"
              placeholder="blurred"
              alt="Sheila"
            />

            <StaticImage
              className="w-[271px]"
              src="../../images/team/profiles/Person.jpg"
              placeholder="blurred"
              alt="Sheila Avatar"
              style={{position: 'absolute', top: 0, left: 0}}
            />
            <div className={memberCss}>Sheila</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CompanyPage = ({location}) => {
  useEffect(() => {
    location?.state?.scrollY && window.scroll(0, location?.state?.scrollY);
  }, [location?.state?.scrollY]);

  const [modalShowingBoard, setModalShowingBoard] = useState(false);
  const [showData, setShowData] = useState({emiliano: false, josh: false});

  const {placeholderImage, background} = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
          }
        }
        background: file(relativePath: {eq: "bg/rebrand/bg-main-middle.png"}) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
          }
        }
      }
    `
  );

  const bgImage = convertToBgImage(getImage(placeholderImage));
  const bgImageMiddle = convertToBgImage(getImage(background));

  return (
    <Layout
      seo={
        <Seo
          title="Who We Are | Trustle"
          description="We are dynamic permissions for citizen developers. Our mission is to enable every organization of any size to automate access control and compliance reviews easily and highly effectively."
        />
      }
    >
      {/* hero section */}
      <section className="overflow-hidden transform">
        <BackgroundImage
          Tag="section"
          // Spread bgImage into BackgroundImage:
          {...bgImage}
          preserveStackingContext
        >
          <div className="flex flex-col pt-16 pb-8 mx-5 md:mx-8 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro
              isPageHeader
              hero="Who is Trustle?"
              title={
                <div dangerouslySetInnerHTML={{__html: `Access when you need it... <br>secured when you don’t`}} />
              }
              description="Our mission is to enable every organization of any size to automate access control and compliance reviews easily and effectively."
            />
          </div>
          <div className="flex flex-col xl:flex-row text-blue justify-center items-center max-w-[1920px] lg:mx-8 xl:mx-auto pt-16 xl:py-0">
            <div className="relative w-full mb-8 xl:mb-0">
              <StaticImage
                src="../../images/placeholders/about-us-history.jpg"
                alt="People working happily"
                quality={100}
                height={754}
                width={1019}
              />
              <div className="absolute top-8 left-6 xl:hidden">
                <StaticImage src="../../images/logo-arrow-white.svg" alt="Trustle logo" quality={100} width={40} />
                <div className="text-2xl font-bold text-white md:text-3xl">Our story</div>
              </div>
            </div>

            <div className="w-full px-8 lg:px-0 ">
              <div className="text-black xl:pl-8 2xl:pl-16 lg:px-5">
                <div className="relative z-10 hidden mb-4 text-2xl font-bold xl:text-4xl text-blue md:mb-4 2xl:mb-8 xl:block ">
                  <span className="relative z-10 ">
                    <span className="relative z-20 ">Our story</span>
                    <div className=" w-full h-[13px] bg-[#C2E5FF] bg-opacity-50 absolute -bottom-1 z-0"></div>
                  </span>
                </div>

                <p className="xl:max-w-[843px] ">
                  <span className="text-2xl font-bold text-blue">
                    Trustle was founded in 2019 on the simple question of “why can’t I find out who has access to what
                    and why?”
                  </span>
                  <br />
                  <br />
                  <span className="text-xl opacity-60">
                    As practitioners ourselves, the seemingly never-ending stream of approval requests that the business
                    generates daily was overwhelming; it was similarly impossible to manage cloud resources and code
                    repositories with using the standard roles and groups. In addition we found that not only was there
                    employee access issue, but contractors, systems and customer access that was not being properly
                    managed and tracked.
                    <br />
                    <br /> It became clear to us that there was still work to be done on cloud platforms and operations
                    management. Having created workload identity and access solutions with
                    <SimpleLink url="https://spiffe.io/" label="SPIFFE" />, Emiliano realized that the same issues are
                    present in today's SaaS systems.
                    <br />
                    <br />
                    That’s why, similar to <SimpleLink url="https://spiffe.io/" label="SPIFFE" />, Trustle implements
                    temporal access constraints and cleans up access when it’s not in use. We founded the company to
                    answer all of those needs in a much deeper, more meaningful way. Trustle now offers deep integration
                    across platforms, with many more integrations on the way.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </section>

      {/* New Profiles Section */}
      <section>
        <BackgroundImage
          Tag="section"
          // Spread bgImage into BackgroundImage:
          {...bgImageMiddle}
          preserveStackingContext
        >
          <div className="overflow-hidden transform ">
            <div className="pt-16 md:pt-32">
              <div className="px-5 pt-0 mx-auto mb-20 text-blue max-w-8xl">
                <TitleBordered title="The Trustle Team" />

                <div className="max-w-[1360px] mx-auto mb-4 text-xl text-center md:text-2xl">
                  Our founders created Trustle to address the gaps they experienced in properly securing cloud platforms
                  and production infrastructure. Cloud systems are both the most critical and (often) least protected
                  assets, mainly because current solutions lack the depth and alignment with modern operating models.
                </div>
              </div>

              <div className="flex flex-col text-blue max-w-[1255px] 2xl:max-w-[1400px] mx-auto px-8">
                <BoardManagement setModalShowing={setModalShowingBoard} />
                <BoardModal
                  showing={modalShowingBoard}
                  close={() => {
                    setModalShowingBoard(false);
                  }}
                />
                <BoardModalMobile showData={showData} setShowData={setShowData} />
              </div>
            </div>
          </div>

          {/* Profiles Our Team */}
          <div className="overflow-hidden transform ">
            <div className="py-16 md:py-32">
              <div className="flex flex-col text-blue max-w-[1255px] 2xl:max-w-[1400px] mx-auto">
                <Profiles />
              </div>
            </div>
          </div>
        </BackgroundImage>
      </section>

      <section className="">
        <QuestionSection />
      </section>
    </Layout>
  );
};

export default CompanyPage;
